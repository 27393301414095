<template>
  <div class="none" >
   <!-- <img class="none-logo" src="@/assets/img/404/logo.svg" @click="() => this.$router.push({name: 'Body'})" /> -->
    <div class="none-err">404</div>
    <!--  <img class="none-main" src="@/assets/img/404/main.svg" /> -->
  </div>
</template>

<script>
import style from './404-style.scss';

export default {
  name: '404',
  props: [],
  data(){
    return{
      
    }
  },
  mounted(){
    
  }
}
</script>
